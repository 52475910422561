/**
 * @summary postMessage 发送消息
 * @param {string} keyword - 关键字：描述发送消息作用
 * @param {object|string|number} data - 消息
 * @param {string} aimOrigin - 验证目标地址
 */
export const postMessageSendInfo = (keyword, data, aimOrigin = window.location.origin) => {
  // 参数组装
  const message = { keyword, data }
  // 判断环境
  const iframe = document.getElementsByTagName('iframe')[0]
  if (iframe) {
    // 父环境
    iframe.contentWindow.postMessage(JSON.stringify(message), aimOrigin)
  }
  else {
    // 子环境
    parent.postMessage(JSON.stringify(message), aimOrigin)
  }
}

/**
 * @summary postMessage 接收消息
 * @param {function|null} cb - 回调函数：返回关键字keyword和数据data
 * @param {object|null} exchange - 回礼：{ keyword, data }
 * @param {string} aimOrigin - 验证目标地址
 */
export const postMessageReceiveInfo = (cb = null, exchange = null, aimOrigin = window.location.origin) => {
  window.addEventListener('message', receiveMessage, false)
  function receiveMessage(event) {
    if (event.origin !== aimOrigin) return
    if (event.data) {
      try {
        const { keyword, data } = JSON.parse(event.data)
        if (typeof cb === 'function') cb(keyword, data)
        if (typeof exchange === 'object') event.source.postMessage(JSON.stringify(exchange), event.origin)
      }
      catch (err) {
        console.log(err)
      }
    }
  }
  return {
    removeListMessage: () => {
      window.removeEventListener('message', receiveMessage, false)
    }
  }
}
