<!--
 * @Author: chenyc
 * @Date: 2021-09-09 14:14:24
 * @LastEditTime: 2021-09-13 20:48:24
 * @LastEditors: chenyc
-->
<template>
  <div>
    <el-upload class="avatar-uploader" action="#" :show-file-list="false" :before-upload="beforeUpload" :http-request="upload" :accept="typeList" :on-remove="handleRemove">
      <img v-if="value" :src="value" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>

<script>
// import { ciKey } from '@/api/cosApi/index';
import { cosObj, messageAction } from '@/utils/utils'
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    format: {
      type: Array,
      default: () => (['image/jpg', 'image/png', 'image/gif', 'image/jpeg'])
    },
    byteToMb: {
      type: Number,
      default: () => (Math.pow(1024, 2))
    }
  },
  data() {
    return {
      dialogImageUrl: '',
      typeList: 'image/*'
      // cosKey: {
      //   bucket: '',
      //   expireTime: 0,
      //   region: '',
      //   secretId: '',
      //   secretKey: '',
      //   token: ''
      // }
    }
  },
  computed: {
    cosKey() {
      return this.$store.getters['cos/cosKey']
    }
  },
  created() {
    this.getCiKey('/')
  },
  methods: {
    getCiKey(dir) {
      // ciKey(dir).then(res => {
      //   if (res.code === 0) {
      //     Object.assign(this.cosKey, res.data);
      //   }
      // });
      this.$store.dispatch('cos/getCiKey', { dir })
    },
    beforeUpload(file) {
      // this.uploading = true;
      const isJPG = file.type
      const isLt2M = file.size / this.byteToMb
      // if (isJPG == 'image/jpg' || isJPG == 'image/png' || isJPG == 'image/gif' || isJPG == 'image/jpeg') {
      // } else {
      //   // this.uploading = false;
      //   this.$message.error('上传图片格式只能是jpg,jpeg,png,gif格式!');
      //   return false;
      // }
      if (!this.format.includes(isJPG)) {
        this.$message.error('上传图片格式只能是jpg,jpeg,png,gif格式!')
        return false
      }
      if (isLt2M >= 2) {
        this.$message.error('上传图片大小不能超过 2MB!')
        return false
      }
      // if (isLt2M < 2) {
      // } else {
      //   // this.uploading = false;
      //   this.$message.error('上传图片大小不能超过 2MB!');
      //   return false;
      // }
    },
    upload(params) {
      if (params.file) {
        const cos1 = cosObj(this.cosKey)
        // Bucket: 'bu3-saas-dev-1256488924' /* 必须 */,
        // Region: 'ap-chengdu' /* 存储桶所在地域，必须字段 */,
        // Key: '/saas/cms/'+new Date().getTime() + '-' + file.name, /* 必须 */
        // Body: file,
        // ContentType: 'application/octet-stream',
        cos1.sliceUploadFile(
          {
            Bucket: this.cosKey.bucket,
            Region: this.cosKey.region,
            Key: '/saas/cms/' + new Date().getTime() + '-' + params.file.name,
            Body: params.file,
            ContentType: 'application/octet-stream',
            onTaskReady: taskId => {
              // console.log(taskId)
              // mytaskId = taskId
              // this.cosArr.push({
              //   taskId: taskId,
              //   cos: cos1,
              //   percentage: 0,
              //   title: title,
              //   name: title,
              //   startDown: true,
              //   //  图片，文件，视频的 下载地址
              //   src: '',
              //   // 如果有预览图，则为预览图的地址
              //   imgSrc: '',
              //   videoImg: ''
              // })
            },
            onProgress: function(progressData) {
              // console.log(progressData)
            }
          },
          (err, data) => {
            if (err) {
              // if (String(err).toLowerCase() === 'error: cors blocked or network error') {
              //   this.$store.dispatch('cos/getCiKey', { dir: '/', isCore: 1 });
              //   messageAction('上传失败，请重新上传');
              // }
              this.$store.dispatch('cos/getCiKey', { dir: '/', isCore: 1 })
              messageAction('上传失败，请重新上传')
              return
            }
            this.dialogImageUrl = 'http://' + data.Location // 用于前端回显
            this.$emit('input', this.dialogImageUrl)
            this.$emit('imgUrlName', params.file.name)
            // this.fileList = this.fileList.map(item => {
            //   if (item.uid === this.currentImageUid) {
            //     return { url: 'http://' + data.Location, name: item.name };
            //   }
            //   return item;
            // });
            // // this.dialogImageUrl = 'http://' + data.Location; // 用于前端回显
            // // console.log(this.fileList)
            // this.$emit('input', 'http://' + data.Location);
          }
        )
      }
    },
    // 移除图片
    handleRemove(file, fileList) {
      // console.log(file)
      // console.log(fileList)
    }
  }
}
</script>
