<!--
 * @Author: chenyc
 * @Date: 2021-09-09 17:25:06
 * @LastEditTime: 2021-09-14 20:38:47
 * @LastEditors: chenyc
-->
<template>
  <div class="importFiles-main">
    <el-upload
      class="upload-demo"
      action="#"
      :before-upload="beforeUploadVideo"
      :http-request="upload"
      :on-change="change"
      :on-remove="change"
      multiple
      :limit="1"
      :accept="typeList"
      :file-list="fileList"
    >
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
  </div>
</template>
<script>
// import { ciKey } from '@/api/cosApi/index';
import { cosObj, messageAction } from '@/utils/utils'
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    fileListProp: {
      type: Array,
      default: () => []
    },
    format: {
      type: Array,
      default: () => (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb'])
    },
    byteToMb: {
      type: Number,
      default: () => (Math.pow(1024, 2))
    }
  },
  data() {
    return {
      typeList: 'video/*',
      fileList: []
      // cosKey: {
      //   bucket: '',
      //   expireTime: 0,
      //   region: '',
      //   secretId: '',
      //   secretKey: '',
      //   token: ''
      // }
    }
  },
  computed: {
    cosKey() {
      return this.$store.getters['cos/cosKey']
    }
  },
  watch: {
    fileListProp: {
      handler(val) {
        this.fileList = JSON.parse(JSON.stringify(val))
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.getCiKey('/')
  },
  methods: {
    getCiKey(dir) {
      // ciKey(dir).then(res => {
      //   if (res.code === 0) {
      //     Object.assign(this.cosKey, res.data);
      //   }
      // });
      this.$store.dispatch('cos/getCiKey', { dir })
    },
    // 视频上传前 格式限制
    beforeUploadVideo(file) {
      const isLt10M = file.size / this.byteToMb < 10
      if (this.format.indexOf(file.type) === -1) {
        this.$message.error('请上传正确的视频格式')
        return false
      }
      if (!isLt10M) {
        this.$message.error('上传视频大小不能超过10MB哦!')
        return false
      }
    },
    // handleVideoSuccess(res, file) {
    //   console.log(file);
    //   console.log('111111');
    //   // this.fileSub(file.raw);
    // },
    // 移除事件
    handleRemove(file, fileList) {
      console.log('22222222222222222')
      // console.log(file, fileList);
    },
    // handlePreview(file) {
    //   console.log('3333333333333333')
    //   // console.log(file);
    // },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    // beforeRemove(file, fileList) {
    //   return this.$confirm(`确定移除 ${file.name}？`);
    // }
    // upload(params) {
    //   console.log(params)
    // },
    upload(params) {
      if (params.file) {
        const cos1 = cosObj(this.cosKey)
        cos1.sliceUploadFile(
          {
            Bucket: this.cosKey.bucket,
            Region: this.cosKey.region,
            Key: '/saas/cms/' + new Date().getTime() + '-' + params.file.name,
            Body: params.file,
            ContentType: 'application/octet-stream',
            onTaskReady: taskId => {},
            onProgress: function(progressData) {
              // console.log(progressData)
            }
          },
          (err, data) => {
            if (err) {
              // if (String(err).toLowerCase() === 'error: cors blocked or network error') {
              //   this.$store.dispatch('cos/getCiKey', { dir: '/', isCore: 1 });
              //   messageAction('上传失败，请重新上传');
              // }
              this.$store.dispatch('cos/getCiKey', { dir: '/', isCore: 1 })
              messageAction('上传失败，请重新上传')
              return
            }
            // this.fileList = this.fileList.map(item => {
            //   if (item.uid === this.currentImageUid) {
            //     return { url: 'http://' + data.Location, name: item.name };
            //   }
            //   return item;
            // });
            this.fileList = [{ url: 'http://' + data.Location, name: params.file.name }]
            // this.dialogImageUrl = 'http://' + data.Location; // 用于前端回显
            // console.log(this.fileList)
            this.$emit('input', 'http://' + data.Location)
            this.$emit('videoName', params.file.name, 'http://' + data.Location)
          }
        )
      }
    },
    change(file, fileList) {
      if (!fileList.length) {
        this.$emit('input', '')
      }
      this.fileList = fileList
    }
  }
}
</script>
<style lang="less" scoped>
@deep: ~'>>>';
.importFiles-main {
  width: 200px;
  display: flex;
  @{deep} .upload-demo {
    .el-upload--text {
      width: 0;
    }
  }
}
</style>
