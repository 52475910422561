/**
* @Author: junlan.he
* @date: 2021/12/10
* @desc:
*/
<template>
  <div>
    <!-- <el-button type='primary' @click='dialogVisible = true'>素材选择</el-button> -->
    <BaseDialog :visible.sync="dialogVisible" class="materialDialog" title="选择素材" width="1100px" destroy-on-close>
      <Materials :type="type" @close="dialogVisible = false" @comfirm="comfirmMaterials" />
    </BaseDialog>
  </div>
</template>

<script>
import Materials from '@/views/cusGroupOperateSop/components/materials.vue'

export default {
  name: 'MaterialBtn',
  components: { Materials },
  props: {
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    comfirmMaterials(data) {
      this.$emit('save', data[0])
      this.dialogVisible = false
    }
  }
}
</script>

<style scoped>
</style>
