<!--
 * @Author: chenyc
 * @Date: 2021-09-09 20:00:02
 * @LastEditTime: 2021-09-16 16:23:08
 * @LastEditors: chenyc
-->
<template>
  <div class="importFiles-main">
    <el-upload
      class="upload-demo"
      action="#"
      name="file"
      :before-upload="beforeUpload"
      :on-exceed="exceedFile"
      :http-request="upload"
      :on-change="change"
      :on-remove="change"
      multiple
      :limit="1"
      :file-list="fileList"
      accept=".txt,.pdf,.zip,.rar,.xlsx,.xls,.doc,.docx"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">只能上传<br /><span style="color:#cb4e49">txt,pdf,zip,rar,doc,docx,xlsx,xls</span><br />文件且不超过20M</div>
    </el-upload>
  </div>
</template>

<script>
// import { ciKey } from '@/api/cosApi/index';
import { cosObj, messageAction } from '@/utils/utils'
export default {
  name: '',
  components: {},
  props: {
    fileListProp: {
      type: Array,
      default: () => []
    },
    format: {
      type: Array,
      default: () => (['txt', 'pdf', 'zip', 'rar', 'doc', 'docx', 'xlsx', 'xls'])
    },
    byteToMb: {
      type: Number,
      default: () => (Math.pow(1024, 2))
    }
  },
  data() {
    return {
      fileList: []
      // cosKey: {
      //   bucket: '',
      //   expireTime: 0,
      //   region: '',
      //   secretId: '',
      //   secretKey: '',
      //   token: ''
      // },
    }
  },
  computed: {
    // ...mapGetters(['userOrglist'])
    cosKey() {
      return this.$store.getters['cos/cosKey']
    }
  },
  watch: {
    fileListProp: {
      handler(val) {
        this.fileList = JSON.parse(JSON.stringify(val))
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.getCiKey('/')
  },
  mounted() {},
  methods: {
    getCiKey(dir) {
      // ciKey(dir).then(res => {
      //   if (res.code === 0) {
      //     Object.assign(this.cosKey, res.data);
      //   }
      // });
      this.$store.dispatch('cos/getCiKey', { dir })
    },
    /**
     * @description: 文件上传操作
     * @Autor: chenyc
     * @Date: 2021-07-14 17:33:26
     * @param {*} queryData
     */
    // 文件超出个数限制时的钩子
    exceedFile(files, fileList) {
      this.$message.warning(`只能选择 1 个文件，当前共选择了 ${files.length + fileList.length} 个`)
    },
    // 文件上传前 限制格式
    beforeUpload(file) {
      const isXLSX = file.name.substring(file.name.lastIndexOf('.') + 1)
      const isLt20M = file.size / this.byteToMb < 20
      if (this.format.indexOf(isXLSX) === -1) {
        this.$message.error('请上传正确的文件格式')
        return false
      }
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过20MB哦!')
        return false
      }
    },
    upload(params) {
      if (params.file) {
        const cos1 = cosObj(this.cosKey)
        cos1.sliceUploadFile(
          {
            Bucket: this.cosKey.bucket,
            Region: this.cosKey.region,
            Key: '/saas/cms/' + new Date().getTime() + '-' + params.file.name,
            Body: params.file,
            ContentType: 'application/octet-stream',
            onTaskReady: taskId => {},
            onProgress: function(progressData) {
              // console.log(progressData)
            }
          },
          (err, data) => {
            if (err) {
              // if (String(err).toLowerCase() === 'error: cors blocked or network error') {
              //   this.$store.dispatch('cos/getCiKey', { dir: '/', isCore: 1 });
              //   messageAction('上传失败，请重新上传');
              // }
              this.$store.dispatch('cos/getCiKey', { dir: '/', isCore: 1 })
              messageAction('上传失败，请重新上传')
              return
            }
            // this.fileList = this.fileList.map(item => {
            //   if (item.uid === this.currentImageUid) {
            //     return { url: 'http://' + data.Location, name: item.name };
            //   }
            //   return item;
            // });
            this.fileList = [{ url: 'http://' + data.Location, name: params.file.name }]
            this.$emit('input', 'http://' + data.Location)
            this.$emit('fileName', params.file.name)
          }
        )
      }
    },
    change(file, fileList) {
      if (!fileList.length) {
        this.$emit('input', '')
      }
      this.fileList = fileList
    }
  }
}
</script>
<style lang="less" scoped>
@deep: ~'>>>';
.importFiles-main {
  width: 200px;
  display: flex;
  @{deep} .upload-demo {
    .el-upload--text {
      width: 0;
    }
  }
}
</style>
