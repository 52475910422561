<template>
  <div>
    <!-- <button @click="send">发送</button> -->
    <iframe v-if="cms" id="iframe" class="iframe" :src="cms+'/cms/basicmaterials/materials?isIframe=true&type='+type" />
  </div>
</template>
<script>
import { postMessageSendInfo, postMessageReceiveInfo } from '@/utils/iframe.js'
export default {
  props: {
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      // cms: 'http://127.0.0.1:3100',
      cms: '',
      postMessage: null
    }
  },
  mounted() {
    this.cms = location.origin
    // setTimeout(()=>{
    //   postMessageSendInfo('materials',{
    //     a:1
    //   },this.cms)
    // }, 1000)
    this.postMessage = postMessageReceiveInfo((keyword, data) => {
      if (keyword === 'materialsPost') {
        console.log('op 接收', data)
        if (data.type === 'comfirm') {
          this.$emit('comfirm', data.data)
        }
        else {
          this.$emit('close')
        }
      }
    }, {}, this.cms)
  },
  destroyed() {
    this.postMessage && this.postMessage.removeListMessage()
  },
  methods: {
    send() {
      postMessageSendInfo('materials', {
        a: 1
      }, this.cms)
    }
  }
}
</script>
<style lang="less" scoped>
.iframe{
  width: 100%;
  height: 90vh;
}
</style>
