<!--
 * @Author: chenyc
 * @Date: 2021-09-06 20:00:41
 * @LastEditTime: 2021-09-16 14:09:49
 * @LastEditors: chenyc
-->
<template>
  <div class="itemmidst">
    <el-form ref="form" :model="conterstr.data" :rules="rules" :disabled="disabled" label-width="100px">
      <el-form-item class="radioSty" prop="contentType">
        <el-radio-group v-model="conterstr.data.contentType" @change="radioChage">
          <template v-for="(value, key) in contentType">
            <el-radio v-if="getContentRadio(key)" :key="key" :label="key">{{ value }}</el-radio>
          </template>
          <!-- <el-radio label="content_type_001" :disabled="conterstr.isType ? true : false">文案</el-radio>
          <el-radio v-for="(item, index) in labelList" :key="index" :label="item.value">{{ item.text }}</el-radio> -->
        </el-radio-group>
        <el-button v-show="indexNum === maxLength - 1" style="padding-left: 10px" size="mini" type="text" @click="addHandle(indexNum)"> 新增内容 </el-button>
        <el-button v-show="maxLength !== 1" style="padding-left: 10px" size="mini" type="text" @click="deleteHandle(indexNum, conterstr.data)"> 删除内容 </el-button>
        <div v-if="validatorInfo.status" class="warning">{{ validatorInfo.message }}</div>
      </el-form-item>
      <!-- 文案 -->
      <template v-if="conterstr.data.contentType === 'content_type_001'">
        <el-form-item label="文本内容" prop="content">
          <!-- <div v-if="indivType==='content_reach_001'||indivType==='content_reach_002'||indivType==='content_reach_003'||isGroup" style="marginBottom:10px">
            <el-button v-for="(it,ind) in btnList" :key="ind" type="primary" @click="insertContent(it)">{{it.name}}</el-button>
          </div> -->
          <el-input :id="'textarea'+stageIndex+indexNum" v-model="conterstr.data.content" type="textarea" :maxlength="4000"></el-input>
        </el-form-item>
      </template>
      <!-- 图片封面 -->
      <template v-else-if="conterstr.data.contentType === 'content_type_002'">
        <el-form-item label="图片文件" prop="imgUrl">
          <div style="display: flex">
            <UploadImg v-model="conterstr.data.imgUrl" @imgUrlName="imgUrlName"></UploadImg>
            <MaterialBtn style="margin-left: 10px" :type="1" @save="(data) => save(data, 1)"></MaterialBtn>
          </div>
        </el-form-item>
      </template>
      <!-- 图文 -->
      <template v-else-if="conterstr.data.contentType === 'content_type_003'">
        <el-form-item label="图文标题" prop="title">
          <div style="display: flex">
            <el-input v-model="conterstr.data.title" type="textarea" :rows="2" :maxlength="128"></el-input>
            <!--            <MaterialBtn style='margin-left: 10px' :type='4' @save='(data)=>save(data,4)'></MaterialBtn>-->
          </div>
        </el-form-item>
        <el-form-item label="图文描述" prop="desc">
          <el-input v-model="conterstr.data.desc" type="textarea" :rows="3" :maxlength="512"></el-input>
        </el-form-item>
        <el-form-item label="图文封面" prop="imgUrl">
          <div style="display: flex">
            <UploadImg v-model="conterstr.data.imgUrl" @imgUrlName="imgUrlName"></UploadImg>
          </div>
        </el-form-item>
        <el-form-item label="图文链接" prop="linkUrl">
          <el-input v-model="conterstr.data.linkUrl" type="input" placeholder="请输入图文链接"></el-input>
        </el-form-item>
      </template>
      <!-- 小程序 -->
      <template v-else-if="conterstr.data.contentType === 'content_type_004'">
        <el-form-item label="小程序标题" prop="title">
          <el-input v-model="conterstr.data.title" type="textarea" :maxlength="64"></el-input>
        </el-form-item>
        <el-form-item label="小程序封面" prop="imgUrl">
          <UploadImg v-model="conterstr.data.imgUrl" @imgUrlName="imgUrlName"></UploadImg>
        </el-form-item>
        <el-form-item label="小程序" prop="miniAppId">
          <el-select v-model="conterstr.data.miniAppId" placeholder="请选择" style="width: 186px">
            <el-option v-for="item in appletArray" :key="item.id" :label="item.name" :value="item.appid"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小程序页面" prop="miniAapPage">
          <el-input v-model="conterstr.data.miniAapPage" type="textarea" :maxlength="128"></el-input>
        </el-form-item>
      </template>
      <!-- 视频 -->
      <template v-else-if="conterstr.data.contentType === 'content_type_005'">
        <el-form-item label="视频文件" prop="videoUrl">
          <div style="display: flex">
            <UploadVideo v-model="conterstr.data.videoUrl" style="position: relative" :file-list-prop="conterstr.data.videoUrl ? [{ name: conterstr.data.fileName, url: conterstr.data.videoUrl }] : []" @videoName="videoName"></UploadVideo>
            <MaterialBtn style="position: absolute; left: 90px" :type="2" @save="(data) => save(data, 2)"></MaterialBtn>
          </div>
        </el-form-item>
      </template>
      <!-- 文件 -->
      <template v-else-if="conterstr.data.contentType === 'content_type_006'">
        <el-form-item label="传统文件" prop="fileUrl">
          <div style="display: flex; align-items: flex-start">
            <UploadFile v-model="conterstr.data.fileUrl" style="position: relative" :file-list-prop="conterstr.data.fileUrl ? [{ name: conterstr.data.fileName, url: conterstr.data.fileUrl }] : []" @fileName="fileName"></UploadFile>
            <MaterialBtn style="position: absolute; left: 90px" :type="3" @save="(data) => save(data, 3)"></MaterialBtn>
            <div style="position: absolute; left: 181px; top: 0px">
              <el-button v-if="conterstr.data.fileUrl" size="small" type="primary" @click="downDownload(conterstr.data.fileUrl)">文件下载 </el-button>
            </div>
          </div>
        </el-form-item>
      </template>
      <!-- 视频号 -->
      <template v-else-if="conterstr.data.contentType === 'content_type_007'">
        <el-form-item label="视频号" prop="content">
          <el-input v-model="conterstr.data.content" placeholder="输入视频号内容的选择指引,30字以内" type="textarea" :maxlength="30"></el-input>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
import UploadImg from './uploadImg'
import UploadVideo from './uploadVideo'
import UploadFile from './uploadFile'
import { windowDownload } from '@/utils/utils'
import configInfo from '@/config/index'
import MaterialBtn from './materialBtn'
import { appletApi } from '@/api/modules/cusGroupOperateSop'

export default {
  name: 'ItemMidst',
  components: { MaterialBtn, UploadImg, UploadVideo, UploadFile },
  props: {
    // 是否显示视频号
    videoFeel: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    indexNum: {
      type: Number,
      default: 0
    },
    maxLength: {
      type: Number,
      default: 0
    },
    conterstr: {
      type: Object,
      default: () => { }
    },
    // 触达类型 -  如果是朋友圈不显示小程序和文件
    indivType: {
      type: String,
      default: () => ''
    },
    validatorInfo: {
      type: Object,
      default: () => ({
        status: false
      })
    },
    isGroup: {
      type: Boolean,
      default: false
    },
    stageIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    const validateUrl = (rule, value, callback) => {
      const reg =
        /^(http(s)?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
      if (!value) {
        return callback(new Error('请上传图文链接'))
      }
      else if (!reg.test(value)) {
        return callback(new Error('请输入合法域名!'))
      }
      else {
        callback()
      }
    }
    return {
      select: 1,
      labelList: [],
      contentType: configInfo.contentType,
      rules: {
        contentType: [{ required: true, message: '请选择内容', trigger: 'change' }],
        content: [
          { required: true, message: '请填写文本内容', trigger: 'blur' },
          { min: 1, max: 4000, message: '长度在 1 到 4000 个字符', trigger: 'blur' }
        ],
        imgUrl: [{ required: true, message: '请上传图片封面', trigger: 'change' }],
        title: [{ required: true, message: '请填写标题', trigger: 'blur' }],
        desc: [{ required: true, message: '请填写描述', trigger: 'blur' }],
        linkUrl: [{ required: true, validator: validateUrl, trigger: 'blur' }],
        miniAppId: [{ required: true, message: '请选择小程序', trigger: 'change' }],
        miniAapPage: [{ required: true, message: '请填写小程序路径', trigger: 'blur' }],
        videoUrl: [{ required: true, message: '请上传视频', trigger: 'change' }],
        fileUrl: [{ required: true, message: '请上传文件', trigger: 'change' }]
      },
      appletArray: [], // 小程序下拉数据
      formBak: {},
      btnList: [],
      sendType: '' // 当前选择的触达类型
    }
  },
  computed: {},
  watch: {
    // conterstr: {
    //   handler() {},
    //   deep: true
    // },
    // imgUrl: {
    //   handler() {
    //     // this.$emit('onChage', this.itemArray);
    //     // console.log(this.conterstr);
    //   },
    //   deep: true
    // }
    // indivType: {
    //   handler(val) {
    //     if (val !== 'content_reach_004') {
    //       this.labelList = [
    //         { value: 'content_type_002', text: '图片' },
    //         { value: 'content_type_003', text: '图文' },
    //         { value: 'content_type_004', text: '小程序' },
    //         { value: 'content_type_005', text: '视频' },
    //         { value: 'content_type_006', text: '文件' }
    //       ];
    //     } else {
    //       this.labelList = [
    //         { value: 'content_type_002', text: '图片' },
    //         { value: 'content_type_003', text: '图文' },
    //         { value: 'content_type_005', text: '视频' }
    //       ];
    //     }
    //   },
    //   deep: true,
    //   immediate: true
    // }
    isGroup: {
      handler(val) {
        console.log(val, '群发1111')
        if (val) {
          // this.getdict()
        }
      },
      deep: true,
      immediate: true
    },
    indivType: {
      handler(val) {
        console.log(val, 1111222444)
        // if (val && (val === 'content_reach_001' || val === 'content_reach_002' || val === 'content_reach_003')) {
        //   this.getdict(val)
        // }
        if (val) this.sendType = val
      },
      // deep: true,
      immediate: true
    },
    sendType(val) {
      if (val) {
        console.log(val, '当前最新类型11')
        // this.getdict()
      }
    }
  },
  created() {
    this.formBak.contentType = this.conterstr.data.contentType || ''
    // 隐藏小程序类型
    // this.appletApi()
  },
  mounted() {
    // this.getdict()
  },

  methods: {
    // 字典
    // getdict() {
    //   const val = this.sendType
    //   getdict({ type: this.isGroup ? 'GROUP_SOP' : 'CUSTOMER_SOP' })
    //     .then(this.thenResolve)
    //     .then((data) => {
    //       if (val && val !== 'content_reach_003') this.btnList = data.slice(1)
    //       else this.btnList = data
    //       console.log(this.btnList, '字典获取值')
    //     })
    // },
    // 插入动态信息
    async insertContent(item) {
      const value = '{' + item.name + '}' || ''
      let str = ''
      const myField = document.querySelector(`#textarea${this.stageIndex}${this.indexNum}`)
      if (myField.selectionStart || myField.selectionStart === 0) {
        const startPos = myField.selectionStart
        const endPos = myField.selectionEnd
        str = myField.value.substring(0, startPos) + value + myField.value.substring(endPos, myField.value.length)
        await this.$nextTick() // 这句是重点, 圈起来
        myField.focus()
        myField.setSelectionRange(endPos + value.length, endPos + value.length)
      }
      else {
        str = value
      }
      this.$set(this.conterstr.data, 'content', str)
    },
    save(data, type) {
      console.log(data, type)
      // type:1 图片 2：视频 3：文件 4：图文
      switch (type) {
        case 1:
          this.$set(this.conterstr.data, 'imgUrl', data.cosPath)
          break
        case 2:
          this.$set(this.conterstr.data, 'videoUrl', data.cosPath)
          this.$set(this.conterstr.data, 'fileName', data.title)
          break
        case 3:
          this.$set(this.conterstr.data, 'fileUrl', data.cosPath)
          this.$set(this.conterstr.data, 'fileName', data.title)
          break
        case 4:
          // 素材cms 缺少两个字段（描述、封面）
          this.$set(this.conterstr.data, 'title', data.title)
          // this.conterstr.data.desc = '';
          // this.conterstr.data.imgUrl = '';
          this.$set(this.conterstr.data, 'linkUrl', data.richContent)
          break
      }
    },
    getContentRadio(type) {
      // sop 触达类型为朋友圈时，不显示视频和文件
      // sop模块才有视频号  其他模块不显示视频号 + 只有朋友圈有视频号
      const { indivType } = this
      const white = ['content_type_004', 'content_type_006']
      const videoWhite = ['content_type_007']
      if (this.videoFeel) return !(indivType === 'content_reach_004' && type && white.includes(type))
      else return type && !videoWhite.includes(type)
    },
    // 获取小程序列表接口
    async appletApi() {
      try {
        const res = await appletApi()
        if (res.code === 0) {
          this.appletArray = res.data
        }
        else {
          this.$notify.error({ title: '提示信息', message: '没有查询到内容!' })
        }
      }
      catch (err) {
        this.$notify.error({ title: '提示信息', message: '查询失败!' })
      }
    },
    // 添加文件名称
    fileName(name) {
      // console.log(name)
      this.conterstr.data.fileName = name || ''
    },
    // 添加视频名称
    videoName(name, imgUrl) {
      this.conterstr.data.fileName = name || ''
      this.conterstr.data.imgUrl = imgUrl ? imgUrl.slice(0, imgUrl.lastIndexOf('.')) + '_0.jpg' : ''
    },
    // 图片名称
    imgUrlName(name) {
      this.conterstr.data.fileName = name || ''
    },
    // 切换 类型清空之前所填写的内容
    radioChage(val) {
      const oldVal = this.formBak.contentType
      this.formBak.contentType = val
      this.conterstr.data = {
        contentType: val,
        id: this.conterstr.data.id
        // ...this.conterstr.data
      }
      if (val === 'content_type_004') {
        this.conterstr.data.miniAapPage = 'page/index/index'
      }
      // console.log(this.conterstr.data)
      this.$emit('radioChage', {
        value: val,
        oldVal
      })
    },
    /**
     * @description: 触发新增新增内容事件
     * @Autor: chenyc
     * @Date: 2021-09-08 16:20:43
     * @param {*} index
     * @return {*}
     */
    addHandle(index) {
      // 新增之前判断必填是否已经填过
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$message.error('完善内容,才可再次新增')
          return
        }
        // // 新增
        // const objStr = {
        //   type: this.conterstr.data.contentType,
        //   indexNum: index
        // };
        // this.$emit('addSubmit', objStr);
        this.$emit('addSubmit', { indexNum: index })
      })
    },
    // 下载
    downDownload(row) {
      windowDownload(row)
    },
    // 触发删除新增内容事件
    deleteHandle(index, row) {
      this.$confirm(`您确定要删除吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async() => {
          const oldVal = this.formBak.contentType
          if (row.id) {
            this.$emit('deleteList', row.id)
          }
          this.$emit('deleteSubmit', { index, oldVal })
        })
        .catch(() => {
          this.$message.info('已取消')
        })
    },
    // 内部组件校验
    VALI_DATE() {
      return new Promise((resolve) => {
        this.$refs.form.validate((valid) => {
          if (!valid) {
            this.$message.error('必填项请完善')
            // resolve(valid);
          }
          else {
            resolve(valid)
          }
        })
      })
    }
  }
}
</script>
<style lang='less' scoped>
@deep: ~'>>>';
.itemmidst {
  @{deep} .el-form {
    .radioSty {
      .el-form-item__content {
        margin-left: 0px !important;
      }
    }

    .el-form-item {
      padding-bottom: 20px;

      .el-form-item__label {
        text-align: left;
      }

      .el-form-item__content {
        .el-textarea {
          width: 348px;
          //height: 92px;
          //textarea {
          //  min-height: 92px !important;
          //  height: 92px !important;
          //}
        }
      }

      .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 120px;
        height: 120px;
      }

      .avatar-uploader .el-upload:hover {
        border-color: #409eff;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
      }

      .avatar {
        width: 120px;
        height: 120px;
        display: block;
      }
    }

    //
    .el-row {
      margin-left: 0 !important;

      .el-form-item {
        .el-form-item__label {
          text-align: left !important;
        }

        .el-form-item__content {
          .el-textarea {
            //textarea {
            //  min-height: 92px !important;
            //  height: 92px !important;
            //}
          }

          .upload-wrap {
            width: 120px !important;
            height: 110px;

            .el-upload__tip {
              display: none;
            }

            .el-upload {
              width: 120px !important;
              height: 110px !important;
              line-height: 110px !important;

              .el-upload-list--picture-card .el-upload-list__item {
                width: 120px !important;
                height: 110px !important;
                line-height: 110px !important;
              }

              .el-upload-list--picture-card .el-upload-list__item-thumbnail {
                width: 120px !important;
                height: 110px !important;
                line-height: 110px !important;
              }

              .avatar {
                width: 120px !important;
                height: 110px !important;
              }
            }
          }
        }
      }
    }
  }
}

.warning {
  position: absolute;
  top: 27px;
  color: red;
  line-height: 1.3;
  font-size: 12px;
}
/deep/.el-form-item__error {
  margin-top: 6px !important;
}
</style>
